import { Validator } from "simple-vue-validator";
import TRACE_API from "@/api/TRACE_API.js";
import axios from "axios";


export default {
    name: "Exceptions",

    components: {},

    data() {
        return {
            Filter: {
                filCreationDateTimeRange: {
                    Start: null,
                    End: null
                },

                TypeName: null,
                ApplicationName: null,

                SessionUserName: null,
                TraceGUID: null
            },

            PaggingInfo: VueUtils.GetPaggingInfoInstance (),

            pagExceptions:null,

            lsts: {
                ApplicationExceptionTypes: []                
            },

            filApplicationExceptionType: null,

            txts: {
                NoResultsMsg: Constants.NoResultsMsg
            }
        }
    },

    mounted() {
        this.Clear(); 
    },

    validators: {
        "Filter.filCreationDateTimeRange.Start": function (pValue) {
            return Validator.value(pValue).required();
        }
    },

    methods: {

        Clear() {
            this.lsts.ApplicationExceptionTypes = [];

            this.Filter.ApplicationName = null;
            this.Filter.TypeName = null;
            this.Filter.SessionUserName = null;
            this.filApplicationExceptionType = null;
            this.Filter.filCreationDateTimeRange.Start = window.SetAsStartDate (new Date ()); 
            this.Filter.filCreationDateTimeRange.End = null;
            this.Filter.TraceGUID = null;

            this.pagExceptions = null;
            this.PaggingInfo.Clear ();
        },

        async LoadApplicationExceptionTypes() {
            this.Filter.ApplicationName = null;
            this.Filter.TypeName = null;
            
            this.ValidateNested(async () => {
                var appExTypes = await TRACE_API.GetApplicationExceptionTypes(this.Filter);

                this.lsts.ApplicationExceptionTypes = 
                    appExTypes.transform (function (aet)
                    {
                        var res = {
                            label: `${aet.ApplicationName} (${aet.TotalExceptionCount})`,
                            options: aet.ExceptionTypesGroup.transform (
                                function (etg) 
                                { 
                                    return { value: etg, text: `${etg.ExceptionTypeName} (${etg.ExceptionCount})` }; 
                                })
                        };
                        return res;
                    }).addSelectOneItem ();
            });
        },

        ShowHideStackTrace (pRowIndex) {
            ShowHideByID ('#divStackTrace_' + pRowIndex);
        },

        TestJSException() {
            alert ('Excepción de prueba JS ejecutada');
            throw new Error ("Excepción de Prueba JS");            
        },

        TestAPIException() {
            $.get (Settings.Trace.RegisterWebServiceURL + '/CheckRegisterException');
        },

        Test404JQueryException() {
            $.post (Settings.Trace.RegisterWebServiceURL + '/Unexistent', {Content:'test'});
        },

        Test404AxiosException() {
            axios.post (Settings.Trace.RegisterWebServiceURL + '/Unexistent', {Content:'test'});
        },

        BrowseExceptionsGroup(pResetPageIndex) {

            this.PaggingInfo.CheckResetPageIndex (pResetPageIndex);

            this.ValidateNested(async () => {
                if (this.filApplicationExceptionType != null)
                {
                    this.Filter.ApplicationName = this.filApplicationExceptionType.ApplicationName;
                    this.Filter.TypeName = this.filApplicationExceptionType.ExceptionTypeName;
                }

                var result = await TRACE_API.GetExceptions ({
                    Filter: this.Filter,
                    PaggingInfo: this.PaggingInfo.GetBaseZeroFromOne()
                });

                this.pagExceptions = result.Results;
                this.PaggingInfo.SetFromBaseZero (result.PaggingInfo);
            });
        }
    }
}